export default function Contact($, selectors, undefined) {
    function bindSubmit() {
        selectors.get('form#contact').on('submit', function (e) {
            e.preventDefault();
            selectors.get('#print-area').empty();
            let self = $(this);
            $.ajax({
                method: 'POST',
                data: self.serializeArray(),
                beforeSend: freezeUI
            })
                .done(handleEmailResult)
                .fail(handleServerError)
                .always(resetUI)
                // .then(clearForm)
        });
    }

    function freezeUI() {
        selectors.get('form#contact').addClass('processing');
    }

    function resetUI() {
        selectors.get('form#contact').removeClass('processing');
        selectors.get('form#contact button').blur();
    }

    function handleEmailResult(data) {
        let result = JSON.parse(data);

        if (result.success) {
            clearForm();
        }

        selectors.get('#print-area').append('<p>' + result.message + '</p>');
    }

    function handleServerError(jqXHR, textStatus, errorThrown) {
        selectors.get('#print-area').append('<p>There was a problem sending your message.<br>Please try again later.</p>');
    }

    function clearForm() {
        selectors.get('form#contact input, form#contact textarea').val('');
    }

    return {
        init: bindSubmit
    };
}
