import SelectorCache from './selector-cache';
import Burger from './burger';
import Contact from './contact-form';
import LightBox from './lightbox';

export default function cypher($, undefined) {
    let selectors = {}

    function init() {
        selectors = new SelectorCache($);

        new Burger($, selectors)();

        // Load contact form js on contact page.
        if (document.location.pathname === '/contact/') {
            new Contact($, selectors).init();
        }

        // Load lightbox js on prints and artwork pages.
        if (/^\/(?:prints|artwork\/[\w-]+)\/$/g.test(document.location.pathname)) {
            new LightBox($, selectors).init();
        }
    }

    return {
        init: init
    }
}
