export default function LightBox($, selectors, undefined){
    function bindLightboxTriggerClick() {
        selectors.get('.lightbox_trigger').on('click', function(e) {
            e.preventDefault();

            //Get clicked link href
            let image_href = $(this).attr("href");

            if (selectors.get('#lightbox').length > 0) { // #lightbox exists
                //place href as img src value
                selectors.get('#lightbox')
                    .find('#content')
                    .html('<img src="' + image_href + '">');
            } else { //#lightbox does not exist - create and insert

                //create HTML markup for lightbox window
                var content =
                    '<div id="lightbox" style="display: none">' +
                    '<p>Click to close</p>' +
                    '<div id="content">' +
                    '<img src="' + image_href +'">' +
                    '</div>' +
                    '</div>';

                selectors.get('body').append(content);
            }

            selectors.get('#lightbox').fadeIn('fast', function() {
                selectors.get('body').addClass('noscroll');
            });
        });
    }

    function bindLightboxClick() {
        //Click anywhere on the page to get rid of lightbox window
        $(document).on('click', '#lightbox', function() {
            selectors.get('#lightbox').fadeOut('fast', function() {
                selectors.get('body').removeClass('noscroll');
            });
        });
    }

    return {
        init: function() {
            bindLightboxTriggerClick();
            bindLightboxClick();
        }
    }
}
