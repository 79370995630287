export default function SelectorCache($) {
    let collection = {};

    function getFromCache(selector) {
        if (undefined === collection[selector] ||
            0 === collection[selector].length) {
            collection[selector] = $(selector);
        }

        return collection[selector];
    }

    return {
        get: getFromCache
    };
}
